import auth from '@/api/auth'

const initialState = {
    user: {
        name: '',
        email: '',
        is_verified: false,
        is_first_login: false,
    },
    token: null,
}

export default {
    namespaced: true,
    state: initialState,
    mutations: {
        SET_USER(state, user) {
            state.user = user
        },
        SET_TOKEN(state, token) {
            state.token = token
        },
        INITIALIZE_STORE(state) {
            Object.assign(state, initialState)
        },
    },
    getters: {
        isVerified: (state) => !!state.user.is_verified,
        isFirstLogin: (state) => !!state.user.is_first_login,
        isAuthenticated: (state) => !!state.token,
    },
    actions: {
        detail({ commit }) {
            return auth.detail().then((response) => {
                commit('SET_USER', response.data.data)
            })
        },
    },
}

export default {
    validation: {
        alpha: '{_field_} 須以英文組成',
        alpha_dash: '{_field_} 須以英數、斜線及底線組成',
        alpha_num: '{_field_} 須以英數組成',
        alpha_spaces: '{_field_} 須以英文及空格組成',
        between: '{_field_} 須介於 {min} 至 {max}之間',
        confirmed: '密碼輸入不一致',
        digits: '{_field_} 須為 {length} 位數字',
        dimensions: '{_field_} 圖片尺寸不正確。須為 {width} x {height} 像素',
        email: '{_field_} 須為有效的電子信箱',
        excluded: '{_field_} 的選項無效',
        ext: '{_field_} 須為有效的檔案',
        image: '{_field_} 須為圖片',
        oneOf: '{_field_} 的選項無效',
        integer: '{_field_} 須為整數',
        length: '{_field_} 的長度須為 {length}',
        max: '{_field_} 不能大於 {length} 個字元',
        max_value: '{_field_} 不得大於 {max}',
        mimes: '{_field_} 須為有效的檔案類型',
        min: '{_field_} 不能小於 {length} 個字元',
        min_value: '{_field_} 不得小於 {min}',
        numeric: '{_field_} 須為數字',
        regex: '{_field_} 的格式錯誤',
        required: '{_field_} 為必填',
        required_if: '{_field_} 為必填',
        size: '{_field_} 的檔案須小於 {size}KB',
        double: '{_field_}字段必須為有效的小數',
        password_strength: '{_field_} 需同時包含大小寫英文與數字，至少 8 個字元',
    },
    messages: {
        welcome: '歡迎回來! 👋',
        third_party_login: '使用 {name} 登入',
        create_an_account: '立即註冊',
        new_on_our_platform: '還不是會員嗎?',
        starts_here: '現在就開始吧 🚀',
        i_agree: '我同意',
        already_have_an_account: '已經是會員了嗎?',
        forgot_password: '忘記密碼? 🔒',
        reset_password_description: '沒關係，重新設定你的密碼吧\n' + '請輸入會員 Email ，以收取密碼重設驗證信',
        send_reset_link: '寄發驗證信',
        back_to_login: '回到登入',
        resend_email_title: '🚀驗證信已成功發送至 : {email}，請到信箱查看',
        resend_email_description: '還沒收到嗎？試著檢查垃圾郵件，或是點擊重新發送',
        reset_password: '重置密碼 🔒',
        logout_success: '登出成功',
        binding_socialite_login: '使用第三方綁定在原有帳號，下次登入可直接使用第三方登入',
        binding_socialite_register: '註冊並綁定第三方登入，下次登入可直接使用第三方登入',
        email_placeholder: '請輸入註冊Email',
        password_placeholder: '可為8-25個字元，必須含數字、英文字母大、小寫',
    },
    fields: {
        password: '密碼',
        email: '電子郵件',
        language: '語言',
        remember_me: '記住我',
        forgot_password: '忘記密碼',
        login: '登入',
        or: '或',
        register_type: '註冊類型',
        company_name: '公司名稱',
        individual: '個人',
        company: '公司',
        first_name: '名字',
        last_name: '姓氏',
        confirm_password: '確認密碼',
        country: '國家',
        sign_up: '註冊',
        privacy_policy_terms: '隱私政策和條款',
        resend: '重新發送',
        new_password: '新密碼',
        set_new_password: '重置新密碼',
        question: '此問題',
        apro: '艾波蘿網站設計有限公司',
    },
    business_code: {
        2001: '驗證信失效，請重新發送',
        2002: '該電子郵件已完成驗證',
    },
}

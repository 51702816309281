import Vue from 'vue'
import { configure, extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import i18n from './i18n'

Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule])
})

configure({
    defaultMessage: (field, values) => {
        let { _field_ } = values
        const { _rule_ } = values

        _field_ = i18n.t(`fields.${field}`)

        return i18n.t(`validation.${_rule_}`, { _field_, _rule_ })
    },
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

extend('password_strength', {
    validate: (value) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,255}$/

        return regex.test(value)
    },
})

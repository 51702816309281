import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// import vee-validate rules and localization setup
require('@/libs/vee-validate')

// import vee-sweet-alerts
require('@/libs/sweet-alerts')

// import bootstrap-vue
require('@/libs/bootstrap-vue')

// import vue-ripple
require('@/libs/vue-ripple')

// import vue-select
require('@/libs/vue-select')
require('@core/scss/vue/libs/vue-select.scss')

Vue.config.productionTip = false

new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')

import axiosIns from '@/libs/axios'

export default {
    login: (data) => axiosIns.post('/auth/login', data),
    logout: () => axiosIns.post('/auth/logout'),
    detail: () => axiosIns.post('/auth/detail'),
    register: (data) => axiosIns.post('/auth/register', data),
    forgotPassword: (data) => axiosIns.post('/auth/forgot-password', data),
    resetPassword: (data) => axiosIns.post('/auth/reset-password', data),
    resendVerification: (data) => axiosIns.get('/verification/resend', { params: data }),
    socialite: (data) => axiosIns.post('/auth/socialite', data),
}

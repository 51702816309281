// noinspection AllyPlainJsInspection

import Vue from 'vue'
// axios
import axios from 'axios'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 10000,
    headers: {
        'X-Country-Code': 'TW',
        'X-Language-Code': 'zh_TW',
    },
})

Vue.prototype.$http = axiosIns

axiosIns.interceptors.request.use((config) => {
    if (store.getters['auth/isAuthenticated']) {
        config.headers.Authorization = `Bearer ${store.state.auth.token}`
    }

    return config
})

axiosIns.interceptors.response.use(
    (response) => {
        return response
    },

    (error) => {
        if (error.response?.data?.message) {
            Vue.prototype.$toast({
                component: ToastificationContent,
                props: {
                    title: error.response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        }

        switch (error.response?.status) {
            case 401:
                window.location.href = '/login'
                break
            case 403:
                window.location.href = '/error-403'
                break
            case 404:
                window.location.href = '/error-404'
                break
            case 500:
                window.location.href = '/error-500'
                break
        }

        return Promise.reject(error)
    }
)

export default axiosIns

import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistedstate from 'vuex-persistedstate'
import Cookies from 'js-cookie'
// Modules
import app from './app'
import appConfig from './app-config'
import global from './global'
import auth from './auth'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        global,
        verticalMenu,
        auth,
    },
    strict: process.env.NODE_ENV !== 'production',
    plugins: [
        new VuexPersistedstate({
            paths: ['global', 'appConfig.layout'],
        }),
        createCookieVuexPersistedstate({
            paths: ['global', 'auth'],
        }),
    ],
})

function createCookieVuexPersistedstate(config, expires = 1) {
    config.storage = {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires, secure: true }),
        removeItem: (key) => Cookies.remove(key),
    }

    return new VuexPersistedstate(config)
}

import global from '@/api/global'

export default {
    namespaced: true,
    state: {
        country: null,
        countries: [],
        language: null,
        languages: [],
        timezone: null,
        socialites: [],
        isLoading: false,
    },
    mutations: {
        UPDATE_COUNTRY(state, val) {
            state.country = val
        },
        UPDATE_COUNTRIES(state, val) {
            state.countries = val
        },
        UPDATE_LANGUAGE(state, val) {
            state.language = val
        },
        UPDATE_LANGUAGES(state, val) {
            state.languages = val
        },
        UPDATE_TIMEZONE(state, val) {
            state.timezone = val
        },
        UPDATE_SOCIALITES(state, val) {
            state.socialites = val
        },
        UPDATE_IS_LOADING(state, val) {
            state.isLoading = val
        },
    },
    actions: {
        bootstrap({ commit, state }) {
            return global.bootstrap().then((response) => {
                const { data } = response.data
                commit('UPDATE_COUNTRY', data.country)

                if (state.language === null) {
                    commit('UPDATE_LANGUAGE', data.language)
                }

                commit('UPDATE_TIMEZONE', data.timezone)
                commit('appConfig/UPDATE_RTL', data.layout_direction, { root: true })

                return response
            })
        },
        countries({ commit }) {
            return global.countries().then((response) => {
                const { data } = response.data
                commit('UPDATE_COUNTRIES', data)

                return response
            })
        },
        languages({ commit }) {
            return global.languages().then((response) => {
                const { data } = response.data
                commit('UPDATE_LANGUAGES', data)

                return response
            })
        },
        socialites({ commit }) {
            return global.socialites().then((response) => {
                const { data } = response.data
                commit('UPDATE_SOCIALITES', data)

                return response
            })
        },
        isLoading({ commit }, val) {
            commit('UPDATE_IS_LOADING', val)
        },
    },
}

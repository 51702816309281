export default {
    validation: {
        alpha: 'The {_field_} field may only contain alphabetic characters',
        alpha_num: 'The {_field_} field may only contain alpha-numeric characters',
        alpha_dash: 'The {_field_} field may contain alpha-numeric characters as well as dashes and underscores',
        alpha_spaces: 'The {_field_} field may only contain alphabetic characters as well as spaces',
        between: 'The {_field_} field must be between {min} and {max}',
        confirmed: 'The {_field_} field confirmation does not match',
        digits: 'The {_field_} field must be numeric and exactly contain {length} digits',
        dimensions: 'The {_field_} field must be {width} pixels by {height} pixels',
        email: 'The {_field_} field must be a valid email',
        excluded: 'The {_field_} field is not a valid value',
        ext: 'The {_field_} field is not a valid file',
        image: 'The {_field_} field must be an image',
        integer: 'The {_field_} field must be an integer',
        length: 'The {_field_} field must be {length} long',
        max_value: 'The {_field_} field must be {max} or less',
        max: 'The {_field_} field may not be greater than {length} characters',
        mimes: 'The {_field_} field must have a valid file type',
        min_value: 'The {_field_} field must be {min} or more',
        min: 'The {_field_} field must be at least {length} characters',
        numeric: 'The {_field_} field may only contain numeric characters',
        oneOf: 'The {_field_} field is not a valid value',
        regex: 'The {_field_} field format is invalid',
        required_if: 'The {_field_} field is required',
        required: 'The {_field_} field is required',
        size: 'The {_field_} field size must be less than {size}KB',
        double: 'The {_field_} field must be a valid decimal',
        password_strength:
            'The {_field_} field must be at least 8 characters long, contain at least one number and one uppercase and lowercase letter',
    },
    messages: {
        welcome: 'Welcome back ! 👋',
        third_party_login: 'Use {name} to login',
        create_an_account: 'Create an account',
        new_on_our_platform: 'New on our platform?',
        starts_here: 'Adventure starts here 🚀',
        i_agree: 'I agree to',
        already_have_an_account: 'Already have an account?',
        forgot_password: 'Forgot Password? 🔒',
        reset_password_description: "Enter your email and we''ll send you instructions to reset your password",
        send_reset_link: 'Send reset link',
        back_to_login: 'Back to login',
        resend_email_title: '🚀Successfully sent to : {email}',
        resend_email_description: "Haven't received it yet? Check spam first, or click here to resend",
        reset_password: 'Reset Password 🔒',
        logout_success: 'Logout Success',
        binding_socialite_login:
            'Use a third party to bind to the original account, and you can use the third party to login directly next time.',
        binding_socialite_register:
            'Register and bind the third-party login, you can directly use the third-party login next time.',
    },
    fields: {
        password: 'Password',
        email: 'Email',
        language: 'Language',
        remember_me: 'Remember me',
        forgot_password: 'Forgot password',
        login: 'Login',
        or: 'or',
        register_type: 'Register Type',
        individual: 'Individual',
        company: 'Company',
        first_name: 'First Name',
        last_name: 'Last Name',
        confirm_password: 'Confirm Password',
        country: 'Country',
        sign_up: 'Sign up',
        privacy_policy_terms: 'privacy policy & terms',
        resend: 'Resend',
        new_password: 'New Password',
        set_new_password: 'Set New Password',
        question: 'Question',
        apro: 'APRO+',
    },
    business_code: {
        2001: 'The verification letter is invalid, please resend it',
        2002: 'The email has been verified',
    },
}

import store from '@/store'
import VerificationScope from '@/enums/VerificationScope'

export default function (to, from, next) {
    let requiredAuth = to.matched.some((record) => record.meta.requiredAuth)

    if (requiredAuth) {
        if (requiredAuth && !store.getters['auth/isAuthenticated']) {
            console.log('Not authenticated')

            return next('/login')
        }

        if (!store.getters['auth/isVerified']) {
            return next({
                path: '/verify-email',
                query: {
                    email: store.state.auth.user.email,
                    scope: VerificationScope.VERIFY_EMAIL,
                },
            })
        }

        if (store.getters['auth/isFirstLogin']) {
            console.log('Not authenticated')

            return next('/welcome')
        }
    }

    next()
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import authenticate from '@/router/guard/authenticate'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/websites-list',
            name: 'websites-list',
            component: () => import('@/views/sub-website/SubWebsitesList.vue'),
            meta: {
                pageTitle: 'WebsitesList',
                breadcrumb: [
                    {
                        text: 'WebsitesList',
                        active: true,
                    },
                ],
                // requiredAuth: true,
            },
        },
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                pageTitle: 'Home',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
                requiredAuth: true,
            },
        },
        {
            path: '/welcome',
            name: 'welcome',
            component: () => import('@/views/Welcome.vue'),
            meta: {
                pageTitle: 'Welcome',
                breadcrumb: [
                    {
                        text: 'Welcome',
                        active: true,
                    },
                ],
                // requiredAuth: true,
            },
        },
        {
            path: '/survey',
            name: 'survey',
            component: () => import('@/views/survey/Survey.vue'),
            meta: {
                pageTitle: 'Survey',
                breadcrumb: [
                    {
                        text: 'Survey',
                        active: true,
                    },
                ],
                requiredAuth: true,
            },
        },
        {
            path: '/building-website',
            name: 'building-website',
            component: () => import('@/views/survey/UnitSelect.vue'),
            meta: {
                pageTitle: 'Building Website',
                breadcrumb: [
                    {
                        text: 'Building Website',
                        active: true,
                    },
                ],
                requiredAuth: true,
            },
        },
        {
            path: '/market',
            name: 'market',
            component: () => import('@/views/templates-market/Market.vue'),
            meta: {
                pageTitle: 'Market',
                breadcrumb: [
                    {
                        text: 'Market',
                        active: true,
                    },
                ],
                requiredAuth: true,
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/general-pages/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/verify-email',
            name: 'verify-email',
            component: () => import('@/views/general-pages/VerifyEmail'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('@/views/general-pages/ForgotPassword.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('@/views/general-pages/Register.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () => import('@/views/general-pages/ResetPassword.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error',
            name: 'error',
            component: () => import('@/views/error/Error.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error-403',
            name: 'error-403',
            component: () => import('@/views/error/Error403.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error-500',
            name: 'error-500',
            component: () => import('@/views/error/Error500.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')

    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

router.beforeEach(authenticate)

export default router
